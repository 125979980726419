import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(home)": [~4,[2]],
		"/admin/member": [~24,[3]],
		"/admin/model": [~25,[3]],
		"/admin/plan": [~26,[3]],
		"/(home)/ask": [~5,[2]],
		"/(home)/assistant": [~6,[2]],
		"/(home)/assistant/create": [~9,[2]],
		"/(home)/assistant/thread/[id]": [~10,[2]],
		"/(home)/assistant/[id]": [~7,[2]],
		"/(home)/assistant/[id]/edit": [~8,[2]],
		"/auth/action": [~27],
		"/auth/email-confirmation": [~28],
		"/auth/email-verification": [~29],
		"/auth/login": [~30],
		"/auth/password/reset": [~31],
		"/(home)/content-generation": [~11,[2]],
		"/(home)/content-generation/create": [~14,[2]],
		"/(home)/content-generation/[id]": [~12,[2]],
		"/(home)/content-generation/[id]/edit": [~13,[2]],
		"/invitation": [~32],
		"/(home)/mfa": [~15,[2]],
		"/(home)/mfa/add": [~16,[2]],
		"/(home)/project": [~17,[2]],
		"/(home)/project/[id]": [~18,[2]],
		"/(home)/project/[id]/[channel]": [~19,[2]],
		"/(home)/search": [~20,[2]],
		"/(home)/search/[id]": [~21,[2]],
		"/share/[id]": [~33],
		"/(home)/subscription/failed": [~22,[2]],
		"/subscription/select": [~34],
		"/team": [~35],
		"/team/create": [~36],
		"/(home)/thread/[id]": [~23,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';